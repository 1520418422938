.rdt_TableHead .rdt_TableCol[data-column-id="fixedLeft"] {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
}

.rdt_TableBody .rdt_TableCell[data-column-id="fixedLeft"] {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
}

.rdt_TableHead .rdt_TableCol[data-column-id="fixedLeft2"] {
  position: sticky;
  left: 150px;
  z-index: 1;
  background-color: #ffffff;
  border-right: 2px solid rgba(0, 0, 0, 0.12);
  box-shadow: 1px 0 5px -3px rgba(0, 0, 0, 0.32);
}

.rdt_TableBody .rdt_TableCell[data-column-id="fixedLeft2"] {
  position: sticky;
  left: 150px;
  z-index: 1;
  background-color: #ffffff;
  border-right: 2px solid rgba(0, 0, 0, 0.12);
  box-shadow: 1px 0 5px -3px rgba(0, 0, 0, 0.32);
}