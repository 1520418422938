.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #3498ff !important;
  color: #fff !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background-color: rgba(204, 233, 255, 0.5) !important;
    color: #1675e0 !important;
}

.btn-clear-date {
    margin-left: -55px !important;
}