
/* tablet */
@media only screen and (min-width: 768px) and (max-width: 992px) { 
	.custom-dropdown {
        right: 35px
    }
}
/* laptop */
@media only screen and (min-width: 992px) {
	.custom-dropdown {
        right: 35px
    }
}