.responsive-daterangepicker {
    width: 100%;
}

@media (max-width: 767px) {
    .rs-picker-daterange-content{
        max-width: 250px !important;
    }
    .rs-picker-daterange-header{
        max-width: 250px !important;
    }
    .rs-picker-daterange-calendar-group {
        display: flex;
        justify-content: start;
        flex-direction: column;
        overflow-y: auto;
        min-width: 250px !important;
        max-width: 250px !important;
    }
}