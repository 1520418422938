// .fc .fc-daygrid-day.fc-day-today {
//     background-color: #edf5f9 !important;
// }

#webpack-dev-server-client-overlay {
  display: none !important;
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: black !important
}

.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-dot-event {
  color: black !important
}

.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-daygrid-event.fc-daygrid-dot-event {
  color: black !important
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}

.tooltip { z-index: 1251 !important; }

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

.swal2-backdrop-show {
  z-index: 1150;
}

#files-upload-add,
#files-upload-update,
#files-upload-pay {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.p-menu ul {
  padding: 0px;
}

@media (min-width: 992px) {
  #sidebar-open-api {
    display: block !important;
  }

  #navbar-open-api {
    display: none !important;
  }
}

.custom-tabs {
  background-color: white;
  padding: 5px;
  width: fit-content;
  border-radius: 5px;
}
.rs-picker-menu {
  z-index: 10000 !important; /* added a large random number, you can choose it more carefully based on your use-case.*/
  position: relative;
}