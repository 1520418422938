.email-subject-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.email-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.email-content-tooltip {
  max-height: 200px;
  overflow-y: auto;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 10px;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 10px;
}

.status-success, .status-done {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-pending, .status-paused {
  background-color: #fff3e0;
  color: #e65100;
}

.status-failed, .status-stopped {
  background-color: #ffebee;
  color: #c62828;
}

.status-running, .status-read {
  background-color: #e0f7fa;
  color: #009EF7;
}

.status-unread {
  background-color: #d0d0d0;
  color: #676767;
}