//
// Main
//

// Wrapper
.docs-wrapper {
	background-color: $docs-page-bg-color;
}

.container {
	margin: 0;
	width: 100%;
	max-width: unset;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

	// Containers
	.container,
	.container-fluid {
		padding: 0 get($docs-content-spacing, desktop);
	}

	// Wrapper
	.docs-wrapper {
		padding-left: get($docs-aside-config, width);
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

	// Containers
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($docs-content-spacing, tablet-and-mobile);
	}
}