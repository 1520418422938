.card-ranking {
    position: relative;
    border: 2px solid #5284E1;
    border-radius: 10px;
    padding-bottom: 100%;
    width: 100%;
    aspect-ratio: auto 1 / 1;
    
    .img-user {
        width: 70%;
        height: 70%;
        object-fit: cover;
        border-radius: 50%;
    }

    .badge-top {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .text-nominal {
        color: #5284E1;
        font-size: 24px;
        font-weight: 600;
    }

    .text-1 {
        font-size: 14px;
        font-weight: 400;
        color: #686869;
    }

    .text-2 {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
    }
}

.card-dashboard {
    border-radius: 10px;
    color: #fff;
}

.purple {
    background-color: #7239EA;
}

.blue {
    background-color: #009EF7;
}

.light-blue {
    background-color: #1AACD2;
}

.red {
    background-color: #F1416C;
}

.orange {
    background-color: #F98332;
}

.green {
    background-color: #18C065;
}

.purple {
    background-color: #7239EA;
}

.light-green {
    background-color: #7EEDAF;
}

.card-effect {
    transition: all 0.5s ease;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    opacity: 1;

    &:hover {
        z-index: 10;
        transform: scale(1.025);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
        opacity: 1;
    }
}

.icon-container {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 30px;
        height: auto;
    }
}