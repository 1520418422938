// Untuk Style Detail Merchant Di dalam AddPipeline
[aria-expanded="true"] .toggle-icon {
    transform: rotate(90deg);
}

.toggle-icon {
    transition: transform 0.2s ease-in-out;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.is-invalid {
    z-index: 0 !important;
}

.badge-ellipsis {
    white-space: nowrap;          /* Mencegah teks membungkus ke baris berikutnya */
    overflow: hidden;             /* Sembunyikan bagian yang melebihi batas */
    text-overflow: ellipsis;      /* Tambahkan elipsis '...' jika teks terlalu panjang */
    display: inline-block;        /* Memastikan elemen inline bisa diberi lebar */
    max-width: 100%;              /* Atur lebar maksimal, atau sesuaikan dengan lebar kontainer */
}

//  End Of Style Detail Merchant Di dalam AddPipeline