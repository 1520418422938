.grid-container {
	columns: 4 400px;
	column-gap: 1rem;
	width: 100%;
	margin: 0 auto;
}

.grid-container div.card {
	width: 150px;
	margin: 0.5rem 0;
	display: inline-block;
	width: 100%;
	transition: all 0.25s ease-in-out;
}

/* Responsif untuk layar lebih kecil */
@media (max-width: 1400px) {
	.grid-container {
		columns: 2 200px;
		column-gap: 1rem;
	}
}

@media (max-width: 768px) {
	.grid-container {
		columns: 1 200px;
		column-gap: 0.5rem;
	}
}
