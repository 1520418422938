// .ql-toolbar {
//     border-top: none !important;
//     border-left: none !important;
//     border-right: none !important;
//     border-bottom: 1px solid #EFF2F5 !important;
//     border: none !important;
//     padding: 8px 17px !important;
// }

// .ql-container {
//     border: none !important;
//     padding: 5px 15px !important;
// }

.ql-editor {
    img {
        width: 200px;
    }
}

