.ql-toolbar-send-mail {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #EFF2F5 !important;
  border: none !important;
  padding: 8px 17px !important;
}

.ql-editor {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100% !important;
}

.ql-editor p {
  white-space: pre-wrap !important;
}

.detail-email-wrap {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100% !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.button-hover:hover {
  color: #6881FF;
}

.button-hover:hover .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #6881FF;
}

.button-hover:hover {
  background-color: #E4E4E4;
}

.button-hover:hover .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: black;
}

.email-content {
  margin-top: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65vw !important;
  max-width: 100% !important;
}

.email-from {
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100dvh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

/* Tambahkan ke file CSS Anda */
.ql-toolbar.ql-snow {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
}

.ql-formats {
  display: flex;
  align-items: center;
}

.ql-variable-container {
  margin-left: auto;
  /* Mendorong elemen ke kanan */
}

.ql-variable-container .btn {
  height: 24px;
  padding: 3px 5px;
  font-size: 13px;
  line-height: 1.2;
  background-color: transparent;
  border: none;
}

.ql-variable-container .btn:hover {
  background-color: #f1f1f1;
}

.ql-variable-container .dropdown-menu {
  font-size: 13px;
  min-width: 120px;
}

// style={{ right: '200px', border: '1px solid #C0C0C0', marginTop: '5px', padding: '5px 10px' }}
.hide-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100dvh;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.custom-placeholder::placeholder {
  font-size: 1.2rem;
}

.search-input {
  max-width: 100%;
}


@media (max-width: 1024px) {

  /* Laptop */
  .email-subject-body {
    max-width: 100vw;
  }

  .email-date {
    width: 8vw;
  }

  .email-from {
    max-width: 20vw;
  }

}

@media (min-width: 1024px) {
  .email-body-content {
    margin-left: 275px !important;
    overflow: auto;
    width: calc(100% - 275px) !important;
    height: 97dvh;
  }

  .email-from {
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 768px) {

  /* Tablet */
  .email-subject-body {
    width: 450px;
  }

  .email-date {
    width: 7vw;
  }

  .email-from {
    max-width: 15vw;
  }
}

@media (max-width: 576px) {

  /* Mobile */
  .email-content,
  .email-from,
  .email-subject-body,
  .email-date {
    max-width: none;
    width: auto;
    height: max-content;
  }

  .email-subject-body {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .email-content,
  .email-from {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
  }

}

.table-broadcast-email {
  max-width: 1100px;
  overflow-x: auto;
}


.wrapper-table-broadcast-email {
  position: relative;
  width: 100%;
  overflow-x: auto;

  // Menambahkan max-width yang responsif
  @media (min-width: 1200px) {
    // xl breakpoint
    max-width: calc(100vw - 355px); // 300px adalah lebar sidebar
  }

  @media (max-width: 1199px) {
    max-width: 100%;
  }

  // Memastikan konten table tidak wrap
  .rdt_Table {
    min-width: 100%;
    white-space: nowrap;
  }
}

// Memastikan card parent tidak overflow
.card.rounded {
  max-width: 100%;
  overflow: hidden;
}