.password-strength {
    margin-top: 5px;
    font-weight: bold;
  }
  
  .password-strength.weak {
    color: red;
  }
  
  .password-strength.medium {
    color: orange;
  }
  
  .password-strength.strong {
    color: green;
  }

  