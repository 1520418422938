.watermark {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none; /* Ensures the watermark doesn't interfere with user interactions */
  font-size: 20px; /* Adjust the font size */
  color: red; /* Adjust the color to match your design */
  width: 50px; /* Set the width to make it a perfect circle */
  height: 50px; /* Set the height to make it a perfect circle */
  border: 2px solid red;
  border-radius: 50%; /* Makes the border circular */
  background-color: black;
}
