.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  // Variant dengan ukuran berbeda
  .spinner-sm {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }

  .spinner-md {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }
  
  .spinner-lg {
    width: 70px;
    height: 70px;
    border-width: 6px;
  }
  
  // Variant dengan warna berbeda
  .spinner-primary {
    border-top-color: #009ef7;
  }
  
  .spinner-success {
    border-top-color: #50cd89;
  }
  
  .spinner-danger {
    border-top-color: #f1416c;
  }