.btnAddBanner {
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    border: 3px dashed #717273;
    border-radius: 0.475rem;
    text-align: center;
    cursor: pointer;
}

.card-banner {
    border: 0px solid white;
    // border-radius: 0.475rem;
}
