.quick-reply-dropdown {
    position: absolute;
    bottom: 175px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    width: fit-content;
    max-width: 50%;
    z-index: 10;
}

.quick-reply-option {
    padding: 10px;
    cursor: pointer;
}

.quick-reply-option:hover {
    background-color: #f0f0f0;
}