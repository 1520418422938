.menu-item-hover:hover {
    background-color: #57c1ff !important;
    color: white !important;
    transition: all 0.2s ease;
}

// membuat transition ketika checkbox muncul seperti di wattsapp

.checkbox-selection-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 100%;
    background-color: #f8f9fa;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.checkbox-selection-sidebar.show {
    transform: translateX(0);
}

.message-container {
    position: relative;
    padding-left: 0;
    transition: padding-left 0.3s ease-in-out;
}

.message-container.with-checkbox {
    padding-left: 48px;
}

.checkbox-wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-48px, -50%);
    padding: 8px 12px;
}

.checkbox-wrapper input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

/* Optional: Add some hover effects */
.message-item:hover .checkbox-wrapper {
    opacity: 1;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(50%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Forward footer styles */
.forward-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-top: 1px solid #e4e6ef;
    z-index: 1000;
    // animation: slideUp 0.3s ease-out;
}

/* Animation classes */
.slide-in-left {
    // animation: 0.3s ease-out;
}

.slide-up {
    // animation: slideUp 0.3s ease-out;
}

.fade-in {
    // animation: fadeIn 0.3s ease-out;
}

/* Optional: Add exit animations */
.slide-out-left {
    // animation: slideInLeft 0.3s ease-out reverse;
}

.slide-down {
    // animation: slideUp 0.3s ease-out reverse;
}

/* Message with checkbox padding */
.message-with-checkbox {
    padding-left: 50px;
    // transition: padding-left 0.3s ease-out;
}

/* Checkbox input custom styles */
.form-check-input {
    cursor: pointer;
    transition: all 0.2s ease;
}

.form-check-input:checked {
    background-color: #009ef7;
    border-color: #009ef7;
}

/* Optional: Add hover effect on messages */
.message-with-checkbox-hover:hover {
    background-color: rgba(0, 0, 0, 0.02);
}